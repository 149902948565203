import React from 'react';
import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import SEO from '../components/layout/SEO';
import Breadcrumb from '../components/layout/Breadcrumb';
import Services from '../components/home/Services';

export default function ServicesPage({ data, location }) {
  const { services } = data;
  return (
    <>
      <SEO title="Services" location={location.href} />
      <Breadcrumb title="Services">
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/services</li>">Services</Link>
        </li>
      </Breadcrumb>
      <Services services={services.nodes} />
    </>
  );
}

ServicesPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

export const query = graphql`
  {
    services: allSanityServices(sort: { fields: name, order: ASC }) {
      nodes {
        id
        name
        slug {
          current
        }
        blurb {
          en
        }
        image {
          asset {
            url
          }
        }
      }
    }
  }
`;
